import type { DeliveryWeekWithFormattedDays } from '@/models';
import { getExceptionDeliveryDate } from '@/utils/exceptionDeliveryDates';
import type { BoxType, Language } from '@ruokaboksi/api-client';
import { formatDateToShortWeekDayDate } from '@ruokaboksi/utils';

const weekDefinition = {
  fi: 'vk',
  en: 'week',
  cs: 'týden',
};

/**
 * Creates a list title based on a delivery week.
 * Logs an error if required params are missing.
 */
export const deliveryWeekToLocaleString = (
  deliveryWeek: DeliveryWeekWithFormattedDays,
  locale: Language
): string => {
  const exceptionDate = getExceptionDeliveryDate(
    new Date(deliveryWeek.deliveryDate)
  );

  if (
    !deliveryWeek ||
    !deliveryWeek.deliveryDate ||
    !deliveryWeek.weekNumberString
  ) {
    return '';
  }

  const dateToUse = exceptionDate ?? deliveryWeek.deliveryDate;
  return `${weekDefinition[locale]} ${
    deliveryWeek.weekNumberString
  } - ${formatDateToShortWeekDayDate(new Date(dateToUse), locale)}`;
};

/**
 * Creates a list title based on a box type.
 * @returns _Original iso 3 reseptiä_ or an empty string.
 */
export const boxTypeDescriptionToLocaleString = (
  boxTypes: BoxType[] | undefined,
  deliveryWeek: DeliveryWeekWithFormattedDays
): string => {
  const { subscription } = useCustomerSubscriptions();

  if (deliveryWeek.paused) {
    const { t } = useI18n();
    return t('delivery_week.paused_week');
  }

  if (!boxTypes) return '';
  const boxType = boxTypes.find((b) => b.id === deliveryWeek.boxTypeId);
  return getBoxPriceTierTitle(
    boxType,
    subscription.value?.defaultNumberOfRecipes
  );
};
